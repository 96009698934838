import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { TopBannerSection } from '../sections/top-banner-section'
import { useBreakpoint } from '../hooks/use-break-point'
import { TopAboutSection } from '../sections/top-about-section'
import { ProductsSection } from '../sections/products-section'
import { TopConceptSection } from '../sections/top-concept-section'

const Outer = styled.div`
`

const ImageContainer = styled.div`
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`

type TopPageProps = {

}
export const TopPage: FunctionComponent<TopPageProps> = () => {
  const isMobile = useBreakpoint('40rem')
  return <Outer>
    <TopBannerSection />
    <TopAboutSection />
    <ProductsSection />
    <TopConceptSection />
  </Outer>
}