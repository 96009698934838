import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { AssetImage } from '../components/image'
import SvgT2 from '../generated/svg/t-2'
import { pageMaxWidth } from '../styles/page-max-width'
import { pxr } from '../styles/pxr'

const Outer = styled.div`
  margin-top: ${pxr(120)};
  margin-bottom: ${pxr(120)};
  @media (max-width: 40rem){
    margin-top: ${pxr(60)};
    margin-bottom: ${pxr(60)};
  }
`

const Inner = styled.div`
  ${pageMaxWidth}

  @media (max-width: 40rem){
    
  }
`

const ImageContainer = styled.div`
  margin-inline: calc(var(--side-margin) * -1);
  img {
    width: 100%;
    height: auto;
  }
`

const Box = styled.div`
display: grid;
grid-template-columns: 1fr 1fr;
gap: 1.5rem;
  @media (max-width: 40rem){
    grid-template-columns: 1fr;
  }
`

const TextContainer = styled.div`

  @media (max-width: 40rem){
    display: contents;
    
  }
`

const Text1 = styled.div`
font-size: 2.25rem;
line-height: 1.7777777777777777;
margin-top: -2rem;
  @media (max-width: 40rem){
    font-size: 1.375rem;
line-height: 1.6363636363636365;
  }
`

const Text2 = styled.div`
font-size: 1.5rem;
line-height: 2.25;
margin-top: ${pxr(43)};
  @media (max-width: 40rem){
    margin-top: ${pxr(23)};
    font-size: 0.8125rem;
line-height: 1.7692307692307692;
  }
`

const Note = styled.div`
font-size: 0.6875rem;
line-height: 1.3636363636363635;
font-family: var(--sans-serif-ja-font);
margin-top: ${pxr(78)};
  @media (max-width: 40rem){
    font-size: 0.625rem;
line-height: 1.4;
order: 999;
margin-top: ${pxr(30)};
  }
`

const GraphContainer = styled.div`

  svg {
    display: block;
    width: 100%;
    height: auto;
  }
`

type TopConceptSectionProps = {

}
export const TopConceptSection: FunctionComponent<TopConceptSectionProps> = () => {
  return <Outer>
    <Inner>
      <ImageContainer>
        <AssetImage src="t_1.png" />
      </ImageContainer>
      <Box>
        <TextContainer>
          <Text1>
            与えるだけの<br />
            スキンケアはもう終わり。
          </Text1>
          <Text2>
            これからは、“育てる→与える→届ける<sup>※1</sup>”<br />
            今まで見過ごしてきた大事なステップをこの1本で。<br />
            <br />
            あなたの美しさは、<br />
            目覚めるように輝き続けます。
          </Text2>
          <Note>
            ※1 角層まで<br />
            ※2 年齢に応じた対策<br />
            ※3 加水分解カイメン（整肌成分）<br />
            ※4 角質層まで
          </Note>
        </TextContainer>
        <GraphContainer>
          <SvgT2 />
        </GraphContainer>
      </Box>
    </Inner>
  </Outer>
}