import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useSwiperClass } from '../hooks/useSwiperClass'
import { Autoplay, EffectFade } from 'swiper/modules'
import { AssetImage } from '../components/image'
import classNames from 'classnames'
import { useBreakpoint } from '../hooks/use-break-point'
import { useData } from '../hooks/use-data'

const Outer = styled.div`
  position: relative;
`

const Slide = styled.a`
  img {
    width: 100%;
    height: auto; 
  }
`

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  z-index: 2;
`
const ControlsItem = styled.div`
  padding: 0.25rem;
  cursor: pointer;
  &::before {
    content: '';
    display: block;
    width: 0.375rem;
    height: 0.375rem;
    border: 1px solid currentColor;
    border-radius: 1rem;
  }
  &.active::before {
    background-color: currentColor;
  }
`


const data = [
  {
    img: 'tb_1.png',
    mobileImg: 'tb_1m.png',
    link: ''
  }
]

type TopBannerSectionProps = {

}
export const TopBannerSection: FunctionComponent<TopBannerSectionProps> = () => {
  const {ref, swiper, slideCount, activeIndex, slideTo} = useSwiperClass()
  const data2 = useData('/shop/products/ZC001')
  const isMobile = useBreakpoint('40rem')
  return <Outer id="top-banner">
    <Swiper
      onSwiper={ref}
      effect="fade"
      fadeEffect={{
      }}
      autoplay={{
        delay: 3000
      }}
      speed={1000}
      modules={[
        EffectFade,
        Autoplay
      ]}
    >
      {
        data.map((d, i) => {
          return <SwiperSlide key={i}>
            <Slide {...d.link ? {href: d.link} : {as: 'div'}}>
              <AssetImage src={isMobile ? d.mobileImg : d.img} />
            </Slide>
          </SwiperSlide>
        })
      }  
      
    </Swiper>
    <Controls>
      {
        Array.from(Array(slideCount)).map((_, i) => {
          return <ControlsItem key={i} className={classNames({active: i === activeIndex})} onClick={() => slideTo(i)} />
        })
      }
    </Controls>
  </Outer>
}