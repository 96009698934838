import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { SectionHead } from '../components/section-head'
import { pageMaxWidth } from '../styles/page-max-width'
import { pxr } from '../styles/pxr'

const Outer = styled.div`

`

const Inner = styled.div`
  ${pageMaxWidth}
  padding-top: ${pxr(120)};
  @media (max-width: 40rem){
    padding-top: ${pxr(80)};
  }
`

const Text1 = styled.div`
  font-size: 2.25rem;
  line-height: 1.7777777777777777;
  text-align: center;
  margin-top: ${pxr(28)};
  @media (max-width: 40rem){
    font-size: 1.375rem;
    line-height: 1.6363636363636365;
    margin-top: ${pxr(25)};
  }
`

const Text2 = styled.div`
  font-size: 1.375rem;
  line-height: 2.25;
  text-align: center;
  margin-top: ${pxr(32)};
  @media (max-width: 40rem){
    font-size: 1rem;
    line-height: 2;
    margin-top: ${pxr(20)};
  }
`

const Note = styled.div`
  font-size: 0.6875rem;
  line-height: 1.3636363636363635;
  margin-top: ${pxr(98)};
  font-family: var(--sans-serif-ja-font);
  @media (max-width: 40rem){
    font-size: 0.625rem;
    line-height: 1.4;
    font-weight: 500;
    margin-top: ${pxr(40)};
  }
`

type TopAboutSectionProps = {

}
export const TopAboutSection: FunctionComponent<TopAboutSectionProps> = () => {
  return <Outer>
    <Inner>
      <SectionHead>
        ABOUT
      </SectionHead>
      <Text1>
      日本初<sup>※1</sup> 美容鍼灸発想<sup>※2</sup> のスキンケア
      </Text1>
      <Text2>
        “微弱電流”により、秘められた美しさを呼び覚まし、自らの輝きを引き出します。<br />
        さらに、エイジングケア<sup>※3</sup>のことを考えた美容液成分を<br />
        天然美容針<sup>※4</sup>で角質層まで注入。ハリツヤ肌へと導きます。
      </Text2>
      <Note>
        ※1「BiRyu」は、ガルバニック原料（ポリフッ化ビニリデン）とスピキュール（加水分解カイメン）を配合した<br />
        　　日本初・日本唯一の化粧品ブランドである点。（2024年10月4日以前において）<br />
        ※2 美容鍼灸に着想を得た微弱電流と天然美容針を使ったスキンケアです。<br />
        ※3 年齢に応じた対策<br />
        ※4 加水分解カイメン(整肌成分)
      </Note>
    </Inner>
  </Outer>
}